import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import { RiPlayList2Line } from "react-icons/ri";
import ButtonX from '../ButtonX'
import audPic1 from '../../images/Frame 220 (1).png'
import audPic2 from '../../images/Frame 222.png'
import audPic3 from '../../images/Frame 223.png'
import audPic4 from '../../images/Frame 226.png'
import audPic5 from '../../images/Frame 220.png'
import audPic6 from '../../images/Frame 227.png'
import audPic7 from '../../images/Frame 232.png'
import audPic8 from '../../images/BORN FOR A PURPOSE.png'
import audPic9 from '../../images/DEPTHS OF GOD.png'
import picKC24 from '../../images/kc2024.jpg'
import tund from '../../images/TUND.jpg'
import pic25 from '../../images/Do not Boast.jpg'
import pic26 from '../../images/SWC.jpg'
import pic0 from '../../images/Redeeming The Times.jpg'
import mlr from '../../images/MLR.jpg'
import audio1 from '../assets/aud1.mp3' 

import plistIcon from '../../images/Vector (2).png'


import Carousel from './Carousel'


const images = [tund, picKC24, audPic6, audPic4, audPic5, mlr, pic0, pic26, pic25];

function SampleNextArrow(props: any) { 
  const { className, currentSlide, slideCount, onClick, slidesToShow } = props;
  console.log(slideCount, currentSlide, slidesToShow);
  
  return (
    <div onClick={onClick} >
        <button onClick={onClick} className={className} >
            <RxCaretRight className={`text-6xl text-white absolute bottom-[10px] z-100 sm:right-[-10px] right-[40px] ${currentSlide >= slideCount - slidesToShow ? 'text-black cursor-not-allowed' : ''}`}
                onClick={onClick}
                
            />
        </button>
    </div>
  ); 
}

function SamplePrevArrow(props: any) {
  const { className, currentSlide, onClick } = props;
  return (
    <div onClick={onClick}>
        <button onClick={onClick} className={className} >
            <RxCaretLeft className={`text-6xl text-white absolute bottom-[10px] !z-100 sm:left-[-40px] left-[29px] ${currentSlide === 0 ? 'text-black cursor-not-allowed' : ''}`}
                onClick={onClick}
            />
        </button>
    </div> 
  );
}


  

const RecentAudio = () => {
    const initialActiveIndex = 0;
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000, 
        autoplaySpeed: 2000,
        // cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow slidesToShow={1} />,
        prevArrow: <SamplePrevArrow  />

        
    };
    return (
        <div className="flex items-center justify-center flex-col pt-[10%] px-[5%] bg-navyBlue min-h-[100vh]  overflow-x-hidden">
            <div className="flex flex-col items-center">
                <div className="flex sm:flex-row  flex-col justify-center items-center gap-x-4">
                    <img src={plistIcon} alt="" className="md:w-10 w-6"/>
                    <h1 className="md:text-[40px]  sm:text-[30px] text-[22px] font-poppins text-center text-white py-5 sm:w-full w-48">Audio Messages</h1>
                </div>
                <p className="text-white font-poppins font-extralight md:text-[20px] text-sm sm:max-w-[600px] max-w-[350px] text-center">Explore our messages designed to inspire and strengthen your faith.</p>

                <div className="relative my-11 hidden flex-col lg:flex">
                    <Carousel items={images} active={initialActiveIndex} />
                </div>

                <div className="flex-col flex lg:hidden md:w-[500px] w-[350px]  my-10">
                    <div className="slider-container">
                        <Slider {...settings}>
                            <img src={tund} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={picKC24} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={audPic6} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={audPic4} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={audPic5} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={mlr} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={pic0} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={pic26} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                            <img src={pic25} alt="" className="sm:!w-80 !w-60 rounded-3xl -!z-10 " />
                        </Slider>
                    </div>
                </div>

                <div className="py-8 xl:my-16 lg:my-12 md:my-8 sm:my-4 my-0  relative">
                    <ButtonX
                        text='More'
                        path='/audio-teachings'
                        arrow={true}
                    />
                </div>


            </div>
        </div>
    )
}

export default RecentAudio
import React, { useRef, useState, useEffect } from 'react';
import { IoPlayOutline, IoPauseOutline } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { ImSpinner2 } from 'react-icons/im'; // Spinner icon for loading state
import { PiPlaylistBold } from "react-icons/pi";
import { RiPlayList2Line } from "react-icons/ri";
import { Previous, Next, PlayCircle, PauseCircle } from 'iconsax-react';
import { LuClock3 } from "react-icons/lu";


interface CardProp {
    img: string;
    audioSrc: string | string[];
    title: string;
    isPlaylistOpen: boolean;
    togglePlaylist: () => void;
}

// Utility function to format time in hh:mm:ss
const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    const formattedHours = hours > 0 ? `${hours}:` : '';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

const AudioCard: React.FC<CardProp> = ({ img, audioSrc, title, isPlaylistOpen, togglePlaylist  }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [showPlaylist, setShowPlaylist] = useState(false);
    const [loading, setLoading] = useState(false);
    const isSeries = Array.isArray(audioSrc);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0); // Track index for the current track
    const [trackDurations, setTrackDurations] = useState<number[]>([]); // Store track durations
    const [isSingleAudio, setIsSingleAudio] = useState(!isSeries); // Track if single audio is playing


    const handleShowPlaylist = () => {
        togglePlaylist();
    };
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Reset to start
            setIsPlaying(false);
        }
    };
    // Toggle play/pause
    const handlePlayPauseAudio = () => {
    if (audioRef.current) {
        if (isPlaying) {
            stopAudio(); // Stop the audio if it's currently playing
        } else {
            // Stop any currently playing audio
            stopAudio();
            audioRef.current.play().catch((error) => {
                console.error("Failed to play audio:", error);
            });
            setIsPlaying(true);
            setIsSingleAudio(!isSeries); // Update state based on whether it's a series or not
        }
    }
};

    // Update progress bar and current time as the audio plays
    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            const updateProgress = () => {
                setCurrentTime(audio.currentTime);
                setProgress((audio.currentTime / audio.duration) * 100 || 0);
            };

            audio.addEventListener('timeupdate', updateProgress);
            audio.addEventListener('loadedmetadata', () => {
                setDuration(audio.duration);
            });

            return () => {
                audio.removeEventListener('timeupdate', updateProgress);
            };
        }
    }, []);

    // Handle scrubbing through the audio
    const handleScrub = (e: React.ChangeEvent<HTMLInputElement>) => {
        const audio = audioRef.current;
        if (audio) {
            const scrubTime = (e.target.valueAsNumber / 100) * audio.duration;
            audio.currentTime = scrubTime;
            setProgress(e.target.valueAsNumber);
        }
    };

    // Handle download with loading state
    const handleDownloadClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!audioSrc) {
            e.preventDefault();
            return;
        }
        setLoading(true); // Set loading to true when the button is clicked
        setTimeout(() => {
            setLoading(false); // Reset loading state after delay
        }, 2000); // Simulate a loading delay
    };

    // Handle play next track
    const handleNextTrack = () => {
        if (currentTrackIndex < (Array.isArray(audioSrc) ? audioSrc.length - 1 : 0)) {
            setCurrentTrackIndex((prevIndex) => prevIndex + 1);
        }
    };

    // Handle play previous track
    const handlePrevTrack = () => {
        if (currentTrackIndex > 0) {
            setCurrentTrackIndex((prevIndex) => prevIndex - 1);
        }
    };

     // Update track durations when the playlist is loaded
    useEffect(() => {
        if (isSeries) {
            const newTrackDurations = Array(audioSrc.length).fill(0); // Initialize duration array

            audioSrc.forEach((src, index) => {
                const tempAudio = new Audio(src);
                tempAudio.addEventListener('loadedmetadata', () => {
                    newTrackDurations[index] = tempAudio.duration;
                    setTrackDurations([...newTrackDurations]); // Update durations
                });
            });
        }
    }, [audioSrc]);

    // Change audio source when the track changes
    useEffect(() => {
        if (audioRef.current) {
            // Stop the currently playing audio if switching from single audio to playlist
            if (isSingleAudio) {
                stopAudio(); // Stop the single audio when starting a track from the playlist
            }
            
            audioRef.current.src = Array.isArray(audioSrc) ? audioSrc[currentTrackIndex] : audioSrc;
            audioRef.current.load();
            if (isPlaying) {
                audioRef.current.play(); // Play the new track if it was already playing
            }
        }
    }, [currentTrackIndex]);

    return (
        <div className="flex flex-col items-center gap-y-5">
            <img src={img} alt={title} className="xl:w-[300px] lg:w-60 w-52 rounded-3xl" />
            <h1 className="font-poppins text-white text-xl w-64 text-center font-light min-h-16">{title}</h1>

            {/* Audio Element */}
            <audio ref={audioRef} preload="auto" />

            {isSeries ? (
                <div className="w-full">
                    <div className="flex flex-col gap-x-5 items-center">
                        <button
                            onClick={handleShowPlaylist}
                            disabled={!audioSrc}
                            className={`bg-greenV px-12 py-4 rounded-md cursor-pointer flex items-center ${!audioSrc ? 'opacity-50 cursor-not-allowed' : ''}`}
                            title={isPlaying ? "Pause Audio" : "Play Audio"}
                        >
                            <PiPlaylistBold />
                        </button>
                        {isPlaylistOpen && (
                            <div className="w-full text-white mt-7 albumBg px-5 py-6 font-poppins">
                                <div className="flex items-center px-6">
                                    <div className="flex items-center gap-x-2 text-lg">
                                        <span>List</span>
                                        <RiPlayList2Line />
                                    </div>
                                    <LuClock3 className="ml-16"/>
                                </div>

                                <div className="flex flex-col text-sm mt-4 gap-y-5">
                                    {Array.isArray(audioSrc) && audioSrc.map((src, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-between bg-lightGray px-6 py-2 font-light rounded-md cursor-pointer"
                                            onClick={() => {
                                                setCurrentTrackIndex(index);
                                                handlePlayPauseAudio(); // Play the selected track
                                            }}
                                        >
                                            <div className="flex items-center gap-2 ">
                                                <span>{`Track ${index + 1}`}</span>
                                            </div>
                                            <div className="flex gap-x-2">
                                                 {formatTime(trackDurations[index] || 0)}
                                                 <a
                                                    href={src}
                                                    download
                                                    onClick={handleDownloadClick}
                                                    className={`text-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                >
                                                    {loading ? (
                                                        <ImSpinner2 className="animate-spin" />
                                                    ) : (
                                                        <MdOutlineFileDownload />
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Playback Control Menu */}
                                <div className="flex justify-center items-center mt-5">
                                    <button onClick={handlePrevTrack} disabled={currentTrackIndex === 0} className=" px-4 py-2 rounded-md" title="Previous Track">
                                        <Previous
                                            size="16"
                                            color="#ffffff"
                                        />
                                    </button>
                                    <button onClick={handlePlayPauseAudio} className=" px-4 py-2 rounded-md" title={isPlaying ? "Pause" : "Play"}>
                                        {isPlaying ? <PauseCircle size="24" color="#ffffff" variant="Bold" /> : <PlayCircle size="24" color="#ffffff" variant="Bold" />}
                                    </button>
                                    <button onClick={handleNextTrack} disabled={currentTrackIndex === (Array.isArray(audioSrc) ? audioSrc.length - 1 : 0)} className=" px-4 py-2 rounded-md" title="Next Track">
                                        <Next
                                            size="16"
                                            color="#ffffff"
                                        />
                                    </button>
                                </div>

                                {/* Progress Bar */}
                                    <>
                                        <input
                                            type="range"
                                            value={progress}
                                            onChange={handleScrub}
                                            className="audio-progress w-full mt-3"
                                            min="0"
                                            max="100"
                                        />
                                        <div className="text-white mt-1 text-xs">
                                            {`${formatTime(currentTime)} / ${formatTime(duration)}`}
                                        </div>
                                    </>
                            
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex gap-x-5 items-center">
                        <button
                            onClick={handlePlayPauseAudio}
                            disabled={!audioSrc}
                            className={`bg-greenV px-12 py-4 rounded-md cursor-pointer flex items-center ${!audioSrc ? 'opacity-50 cursor-not-allowed' : ''}`}
                            title={isPlaying ? "Pause Audio" : "Play Audio"}
                        >
                            {isPlaying ? <IoPauseOutline /> : <IoPlayOutline />}
                        </button>

                        <a
                            href={audioSrc}
                            download
                            onClick={handleDownloadClick}
                            className={`bg-greenV px-12 py-4 rounded-md cursor-pointer flex items-center ${!audioSrc ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {loading ? (
                                <ImSpinner2 className="animate-spin" />
                            ) : (
                                <MdOutlineFileDownload />
                            )}
                        </a>
                    </div>

                    {isPlaying && (
                        <>
                            <input
                                type="range"
                                value={progress}
                                onChange={handleScrub}
                                className="audio-progress w-full mt-3"
                                min="0"
                                max="100"
                            />
                            <div className="text-white mt-1">
                                {`${formatTime(currentTime)} / ${formatTime(duration)}`}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default AudioCard;
